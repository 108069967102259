import React, { Fragment, useState } from "react"
import { CareerStyled } from "./careerStyle"
import { Accordion, Card, Col, Container, Row } from "react-bootstrap"
import { SingleAccordCareer } from "./careerStyle"
import icon1 from "../../images/icons8-permanent-job-32.png"
import { EmailButton, MobileButton, PhoneButton } from "../shared/button/button"
import ModalComponent from "../shared/modal/modal"
import ContactForm from "../shared/contactForm/contactForm"
import careerImg from "../../images/career_img.png"
import careerImg2 from "../../images/career_im2.png"
import { jobOffers } from "../../data/jobOffers"
import { injectIntl } from "gatsby-plugin-intl"

const CareerJobs = ({ intl }) => {
  const [activeTab, setActiveTab] = useState(-1)
  return (
    <CareerStyled>
      <Container>
        <div className={"green-line"}>
          <Row className={"set-padding"}>
            <Col xs={12} lg={6} className={"heading"}>
              <h1>{intl.formatMessage({ id: "career.title" })}</h1>
              <p className={"subtitle"}>{intl.formatMessage({ id: "career.conent.bold" })}</p>
              <p>
                {intl.formatMessage({ id: "career.conent" })}
              </p>
              <p className="subtitle-offer-mobile d-block d-lg-none min-vh">
              {intl.formatMessage({ id: "career.offers.title" })}
              </p>
            </Col>
            <Col lg={3} className={"d-none d-lg-flex"}/>
            <Col lg={3} className={"img-container d-none d-lg-flex"}>
              <img src={careerImg} alt="" />
            </Col>
          </Row>
        </div>
      </Container>
      <div className="bg-gray padding">
        <Container>
          <Row>
            <Col xs={12} lg={6} className={"p-0 bg-gray"}>
              <p className="subtitle-offer-desktop d-none d-lg-block">
              {intl.formatMessage({ id: "career.offers.title" })}
              </p>
              <Accordion>
                {jobOffers.map((offer, index) => (
                  <Fragment key={index}>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey={index + 1}
                      >
                        <SingleAccordCareer
                          onClick={() =>
                            setActiveTab(activeTab === index + 1 ? -1 : index + 1)
                          }
                          active={activeTab === index + 1}
                        >
                          <img src={icon1} alt="" />
                          <span>{offer.name}</span>
                        </SingleAccordCareer>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index + 1}>
                      <Card.Body>
                        {offer.segments.map((item, key) => (
                          <div key={key}>
                            <p className="subtitle-job">{item.title}</p>
                            <div dangerouslySetInnerHTML={{ __html: item.body }} />
                          </div>

                        ))}

                        <div className="button">
                          <ModalComponent scrollable={false} careerView={true}>
                            <MobileButton
                              innerText={"Aplikuj"}
                              bg={"#94C835"}
                              color={"#fff"}
                            />
                            <div className={"modal-custom-content"}>
                              <ContactForm
                                careerView={true}
                                job={offer.name}
                              />
                            </div>
                          </ModalComponent>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  </Fragment>
                ))}
              </Accordion>
            </Col>
            <Col lg={6} className={"d-none d-lg-flex img-container-interview"}>
              <img src={careerImg2} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-field-gray d-block d-lg-none">
        <Container>
          <div className="d-flex justify-content-center">
            <p>
              Nie znalazłeś oferty? Nic straconego!
              <span> Napisz albo zadzwoń</span> i zaproponuj.
            </p>
          </div>
        </Container>
      </div>
      <Container>
        <div className="buttons d-flex d-lg-none">
          <PhoneButton />
          <EmailButton />
        </div>
      </Container>
    </CareerStyled>
  )
}

export default injectIntl(CareerJobs);
